$dark-blue: #003463; // Define a variable for dark blue
$light-cyan: #4cebe9; // Define a variable for light cyan

.confirm-email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 100vw; // Ensure it never exceeds the viewport width
  height: 100vh; // Fill the screen height to prevent overflow
  box-sizing: border-box; // Include padding in height and width

  // Media Query for Tablets
  @media (min-width: 640px) {
    padding: 3rem;

    .confirm-email-image {
      width: 180px;
    }

    .confirm-email-heading {
      font-size: 2rem;
    }

    .confirm-email-description {
      max-width: 320px;
    }
  }

  // Media Query for Desktops
  @media (min-width: 1024px) {
    padding: 4rem;
    height: auto; // Prevents overflow on larger screens
    min-height: 100vh; // Ensures it fills the screen height

    .confirm-email-image {
      width: 220px;
    }

    .confirm-email-heading {
      font-size: 2.5rem;
    }

    .confirm-email-description {
      font-size: 1.125rem;
      max-width: 400px;
    }

    .verification-input-container {
      max-width: 400px;
    }

    .confirm-email-button {
      padding: 1rem;
    }
  }
}

.confirm-email-image {
  width: 150px;
}

.confirm-email-text {
  text-align: center;
  margin-top: 1rem;
}

.resend-container {
  margin-top: 30px;
}

.resend-container p {
  color: #003463;
}

.resend-button {
  text-align: right;
}

.resend-button button {
  padding: 0.75rem;
  background-color: #003463;
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: bold;
  cursor: pointer;
  width: 30%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
}

.resend-button button:hover {
  background-color: #4cebe9;
  color: #003463;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.confirm-email-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: $dark-blue; // Dark Blue Heading
}

.confirm-email-description {
  color: $dark-blue; // Dark Blue
  font-weight: 600;
  max-width: 280px;
  margin-top: 0.5rem;
}

.verification-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin-top: 1rem;
  align-items: center;
}

.confirm-email-button {
  padding: 0.75rem;
  background-color: $dark-blue; // Dark Blue
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: $light-cyan; // Light Cyan
    color: $dark-blue; // Dark Blue on Hover
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}