@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --lightblue: #d4fbfb;
  --darkblue: #0079e8;
  --borderblue: #003463;
  --black: #252525;
  --white: #fff;
  --midblue: #4cebe9;
}
.letter,
.letter-two {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.index-module_type__E-SaG::after {
  color: transparent !important;
}
.login-btn-desktop {
  position: fixed;
  right: 120px;
  top: 20px;
  color: black;
  font-weight: 600;
  z-index: 99999999;
  @media (max-width: 1100px) {
    right: 10px;
    top: 15px;
  }
  &.user-profile {
    margin-top: -5px;
  }
}
.react-tel-input .form-control {
  height: 52px !important;
  width: 100% !important;
}
@media (max-width: 1100px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}
.react-tel-input .country-list {
  text-align: justify;
  z-index: 99999999999999 !important;
}
.modal {
  z-index: 9999999999999999999 !important;
}
.css-t3ipsp-control {
  height: 53px !important;
  min-height: initial !important;
}
.css-13cymwt-control {
  border-radius: 6px !important;
  min-height: initial !important;
  height: 53px;
  padding: 0 5px;
  border-color: #dee2e6 !important;
}
.css-1nmdiq5-menu {
  z-index: 9999999999999 !important;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
body {
  font-family: "Lato", sans-serif !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "Lato", sans-serif !important;
}
a {
  color: var(--black) !important;
}

.ctm-dropdown {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  left: 10px;
  top: 90px;
  z-index: 9;
  width: 94%;
  padding: 10px;
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: #c6d0d3;
    border-radius: 10px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--borderblue);
    border-radius: 10px;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      transition: 0.3s all ease;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #d4fbfb;
      }

      &.selected {
        background-color: #d4fbfb;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.important-link {
  color: #0890f1 !important;
}
.ctm-dropdown-1 {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  left: 0px;
  top: 55px;
  z-index: 9;
  width: 100%;
  padding: 10px;
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: #c6d0d3;
    border-radius: 10px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--borderblue);
    border-radius: 10px;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      transition: 0.3s all ease;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #d4fbfb;
      }

      &.selected {
        background-color: #d4fbfb;
      }
    }
  }
}

.heading-toast {
  color: white;
  font-weight: 1000;
  font-size: 40px;
}

.container-cut {
  padding: 0 60px;
}

a {
  text-decoration: none !important;
}

.custom-btn {
  padding: 13px 40px;
  color: var(--white);
  background-color: var(--borderblue);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  transition: 0.3s linear;

  &:hover {
    background-color: var(--borderblue);
    color: var(--white);
  }
}

.custom-tran-btn {
  border: 1px solid var(--borderblue);
  padding: 13px 30px;
  color: var(--borderblue);
  background-color: transparent;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 600;
  transition: 0.3s linear;

  &:hover {
    background-color: var(--borderblue);
    color: var(--white);
  }
}

.book-order-section {
  padding: 0px 0;
}

.book-order-section .section-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  position: relative;
}

.book-order-section .style-heading {
  font-weight: 800;
  margin-bottom: -15px;
  color: var(--midblue);
}

.book-order-section .place-order {
  border: 1px solid transparent;
  padding: 13px 60px;
  margin-top: 20px;
  color: var(--borderblue);
  background-color: #ffed66;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s linear;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-order-section .place-order .arrow {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  /* Smooth transition for opacity */
  padding-left: 0px;
}

.book-order-section .place-order:hover .arrow {
  opacity: 1;
  padding-left: 8px;
}

/* Add this CSS to your stylesheet */
.fallback-animation .react-svg-wrapper {
  animation: fadeAndScale 1s ease-in-out forwards;
}

@keyframes fadeAndScale {
  from {
    opacity: 1;
    /* Start fully visible */
    transform: scale(1);
    /* Start at normal scale */
  }

  to {
    transform: scale(0.9);
    /* Scale up by 50% */
  }
}

body .layer-effect {
  /* animation: fade linear forwards;
  scale: 0;
  opacity: 100%;
  animation-timeline: view();
  animation-range-start: entry -140px;
  animation-range-end: exit -140px;
  transform-origin: bottom; */
}
#layyer h2 {
  font-size: 43px;
  font-weight: 600;
}
body .sides {
  /* animation: fade linear forwards;
  scale: 0.8;
  opacity: 100%;
  animation-timeline: view();
  animation-range-start: entry -70px;
  animation-range-end: exit -80px;
  transform-origin: bottom; */
}

@keyframes fade {
  100% {
    scale: 1;
    opacity: 100%;
  }
}

/* ============================================================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

main > section {
  height: 100vh;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

/* ============================================================== */
.text-wrapper p {
  line-height: 0;
  margin: 0;
  font-weight: 1000 !important;
  font-size: 40px !important;
}

.text-wrapper-1 p {
  line-height: 0;
  margin: 0;
  font-weight: 600 !important;
  font-size: 45px !important;
  color: var(--darkblue);
}

/* ====================== */
.animate-logo {
  width: 800px;
  height: 100vh;
  object-fit: contain;
  z-index: 999999;
}

@keyframes fadeInScaleMoveUp {
  0% {
    opacity: 1;
    height: 600px;
    transform: translateY(50px) scale(1.5);
  }

  50% {
    opacity: 1;
    height: 400px;
    transform: translateY(0) scale(1);
  }

  100% {
    visibility: visible;
    height: 30px;
    opacity: 1;
    z-index: 1;
    margin-left: 40px;
    margin-top: -30px;
    transform: translateY(-40px) scale(0.7);
  }
}

.shrink {
  position: fixed;
  z-index: 999999;
  transform: scale(0.5);
  padding-left: 60px;
  left: 0;
  right: 0;
  margin: auto;
  animation-name: slideDown;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.expand {
  /* position: fixed; */
  z-index: 999999;
  transform: scale(1);
  padding-left: 60px;
  left: 0;
  right: 0;
  margin: auto;
  animation-name: slideUp;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  from {
    top: 0px;
    height: 100vh;
    transform: scale(0.9);
    transition: 0.4s all ease;
  }

  to {
    top: -50px;
    left: 40px;
    height: 27vh;
    transform: scale(0.22);
    transition: 0.4s all ease;
  }
}

@keyframes slideUp {
  from {
    top: -50px;
    left: 40px;
    height: 27vh;
    transform: scale(0.22);
    transition: 0.4s all ease;
  }

  to {
    top: 0px;
    height: 100vh;
    transform: scale(0.9);
    transition: 0.4s all ease;
  }
}

.left-shrink {
  left: 0;
  right: -100px;
  margin: auto;
}

.fav-img {
  padding-left: 180px;
}

.spline-watermark {
  display: none;
}

/* custom button */

.link {
  border: 1px solid var(--borderblue);
  border-radius: 4px;
  background-color: var(--borderblue);
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  width: 100%;
  position: relative;

  span {
    padding-left: 14px;
    position: relative;
  }

  .pink {
    width: 2em;
    height: 2.7em;
    border-radius: 4px;
    background-color: #5acfcb;

    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: -2px;
  }
}

.link .hover-over,
.links .hover-over,
.linkss .hover-over {
  visibility: hidden;
  transition: 0.3s all ease;
}

.link:hover .hover-over,
.links:hover .hover-over,
.linkss:hover .hover-over {
  visibility: visible;
}

.links {
  margin-top: 20px;
  border: 0px solid var(--borderblue);
  border-radius: 4px;
  background-color: var(--borderblue);
  padding: 11px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  width: 100%;
  position: relative;

  span {
    padding-left: 10px;
    position: relative;
  }

  .pink {
    width: 2em;
    height: 2.7em;
    border-radius: 4px;
    background-color: #42a6bf;
    position: absolute;
    top: 51%;
    transform: translatey(-51%);
    left: -0.15em;
  }
}

.linkss {
  border: 1px solid var(--borderblue);
  border-radius: 4px;
  background-color: var(--borderblue);
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  width: 100%;
  position: relative;

  span {
    padding-left: 14px;
    position: relative;
  }

  .pink {
    width: 2em;
    height: 2.7em;
    border-radius: 4px;
    background-color: #5acfcb;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: -0.15em;
  }
}

/* image text stroke hover */
.img-wrapper-8,
.img-wrapper-7,
.img-wrapper-6,
.img-wrapper-5,
.img-wrapper-4,
.img-wrapper-3,
.img-wrapper-2,
.img-wrapper-1,
.img-wrapper {
  position: absolute;

  width: 15vw;
  height: 15vw;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
  border-radius: 50%;
  mix-blend-mode: var(--white);
  visibility: hidden;
  transform: scale(0.3);
}

.img-placeholder,
.img-placeholder img {
  height: 100%;
  width: 100%;
}

.img-placeholder img {
  object-fit: cover;
  display: block;
}

/*  */
.img-wrapper-1 {
  left: -11vw;
  top: 10vw;
}

.img-wrapper {
  left: -11vw;
  top: -3vw;
}

.img-wrapper-2 {
  left: -11vw;
  top: 50vw;
}

.img-wrapper-3 {
  left: -11vw;
  top: 80vw;
}

.img-wrapper-4 {
  left: -11vw;
  top: 120vw;
}

.img-wrapper-5 {
  left: -11vw;
  top: 150vw;
}

.img-wrapper-6 {
  left: -11vw;
  top: 180vw;
}

.img-wrapper-7 {
  left: -11vw;
  top: 210vw;
}

.img-wrapper-8 {
  left: -11vw;
  top: 240vw;
}

.stroke-text {
  font-size: 30px;
  cursor: pointer;
  -webkit-text-fill-color: var(--borderblue);
}

.stroke-text:hover {
  -webkit-text-stroke: 2px var(--borderblue);
  -webkit-text-fill-color: transparent;
  -webkit-animation: fill 0.5s infinite alternate;
}

.google-play {
  width: 150px;
  height: 50px;
  object-fit: contain;
}

.apple-play {
  width: 150px;
  height: 50px;
  object-fit: contain;
}

@media screen and (max-width: 1023px) {
  .container-cut {
    padding: 0px 20px;
  }

  h1,
  h2 {
    font-size: 30px !important;
  }

  .react-svg-wrapper {
    svg {
      width: 300px;
      height: 300px;
    }
  }
}

/* Typewriter effect 1 */

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.typewriter {
  --caret: currentcolor;
}
.typewriter-1 {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 13.5s infinite;
}
.typewriter-1::before {
  content: "";
  animation: typing1 10s steps(60) infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}
.typewriter-1.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}
.typewriter-1.nocaret::after {
  border-right: 0;
}

@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }
  .typewriter-1::after {
    animation: none;
  }

  @keyframes sequencePopup {
    0%,
    100% {
      content: "Business";
    }

    25% {
      content: "Hotel";
    }

    50% {
      content: "Airbnb";
    }

    75% {
      content: "Cafe";
    }
  }
  @keyframes sequencePopup1 {
    0%,
    100% {
      content: "Dry Cleaners";
    }

    25% {
      content: "Laundry Service";
    }

    50% {
      content: "Dry Cleaners";
    }

    75% {
      content: "Laundry Service";
    }
  }

  .typewriter::before {
    content: "Business";
    animation: sequencePopup 12s linear infinite;
  }
  .typewriter-1.css-1nmdiq5-menu {
    content: "Dry Cleaners";
    // animation: sequencePopup1 5s linear infinite;
  }
}

.typing-style {
  margin-left: -5px !important;
}

@media screen and (max-width: 769px) {
  .typing-style {
    margin-left: -8px !important;
  }

  .mobile-filing {
    margin-top: 20px;
    padding-top: 20px;
  }
}

.transition-container {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
  // pointer-events: none;

  span {
    position: relative;
  }

  &.from-left {
    flex-direction: column;

    span {
      background: #d4fbfb;
      height: 100%;
      width: 0%;
    }
  }
}

.transition-container-1 {
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;

  span {
    position: relative;
  }

  &.from-right {
    flex-direction: column;

    span {
      background: #d4fbfb;
      height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 1400px) {
  .mix-blend-difference {
    mix-blend-mode: difference !important;
  }
  #inform-bg {
    background-color: #000 !important;
  }
  .clss-mobile {
    background-color: #000 !important;
  }
  .fill-white {
    // fill: var(--borderblue) !important;
  }
  .fill-white-1 {
    // fill: #42a6bf !important;
  }
}
@media (max-width: 450px) {
  .header .letter svg {
    margin: 0;
    width: auto !important;
    display: inline-block !important;
    height: 75px;
    margin-top: 100px;
  }
}
