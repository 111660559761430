:root {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1.3;
  --tw-scale-y: 1.3;
  --twMed-scale-x: 2;
  --twMed-scale-y: 2;
  --twM-scale-x: 1;
  --twM-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
:root {
  /* --base-width: 50px; */
  --base-height: 25vh;
}

.letter {
  /* width: var(--base-width); */
  height: var(--base-height);
  display: inline-block;
  margin: 0.2rem;
}

.letter svg {
  display: block;
  width: auto;
  height: 100%;
}

.y-10 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

html {
  scroll-behavior: smooth;
}

#home-height {
  width: 100%;
  height: 100%;
  /* min-height: 450vh; */
}
.spline-watermark {
  display: none !important;
}
.header {
  top: -100px;
  /* right: -10px; */
  position: fixed;
  padding: 0 20px;
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  z-index: -1 !important;
  gap: 10px;
  /* transform: scale(1.6); */
}
/* .header-two .letter-two {
  opacity: 0;
} */
/* .header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  z-index: -1 !important;
} */
.header .letter {
  display: block;
  /* display: flex; */
  align-items: center;
  align-self: center;
}
/* .header .letter h1 {
  font-size: 150px !important;
} */
/* .letter h1 {
  font-size: 8rem;
  transition: font-size 0.3s ease; 
} */
.letter h1 {
  font-size: 155px;
  transition: all 0.3s ease;
}
.header .letter svg {
  width: 100%;
  /* height: 230px; */
}
.header-two {
  /* transform: scale(0.3); */
}

.nav {
  top: 0;
  width: 100%;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3em 0; */
  z-index: 7;
}
.logo {
  /* position: relative; */
}

.logo-revealer {
  /* position: absolute; */
  top: 0;
  /* width: 100%;
  height: 100%; */
  transform-origin: top;
  background-color: #fff;
}
.logo-revealer::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 45px;
  margin: auto;
  /* background: red; */
  z-index: 2;
}
.website-content {
  width: 100%;
  padding-top: 5px;
  position: absolute !important;
  transition: 0.3s all ease;
}
.website-content-1 {
  width: 100%;
  padding-top: 0px;
  transition: 0.3s all ease;
}

.website-content-wrapper {
  width: 100%;
  margin: 0 auto;
}
.img {
  width: 100%;
  /* margin-top: 60px; */
  overflow: hidden;
}
.la {
  color: #174450;
  font-weight: 1000;
}
.mo {
  color: var(--midblue);
  font-weight: 1000;
}
.cls-1 {
  fill: #174450 !important;
}
/* .cls-2 {
  fill: white !important;
} */
/* .white-cls {
  fill: white !important;
} */

/* floating label */
.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}
.did-floating-label {
  color: var(--borderblue);
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border-radius: 2px;
}
.did-floating-input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 46px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    ~ .did-floating-label {
      top: -8px;
      font-size: 13px;
    }
  }
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 15px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 15px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 15px;
}

.input-group {
  display: flex;
  .did-floating-input {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
}
.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}



/* prompt */

@media (max-width: 1300px) {
  .header {
    top: -120px !important;
    /* transform: scale(1.4) !important; */
  }
  .website-content {
    padding-top: 20px !important;
  }
  .header .letter svg {
    /* width: 100%; */
    /* height: 200px !important; */
  }
}

@media screen and (max-width: 1100px) {
  .y-10 {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--twMed-scale-x)) scaleY(var(--twMed-scale-y)) !important;
  }
  /* .website-content.scroll-tag {
    padding-top: 40vh !important;
  } */
  #header {
    top: 0 !important;
  }

  .header {
    top: -140px !important;
    /* transform: scale(1) !important; */
    /* left: 0; */
    gap: 4px;
  }
  .website-content {
    padding-top: 0px;
  }
  .letter h1 {
    font-size: 55px !important;
  }
  .website-content {
    /* padding-top: 250px; */
  }
  .header {
    /* height: 100%; */
  }
  .header-two {
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-left: 50px !important; */
    z-index: 9999;
    gap: 0 !important;
    margin-top: 14px;
  }
}
@media (max-width: 1000px) {
  .header .letter svg {
    /* width: 100%; */
    /* height: 120px !important; */
  }
  #inform-bg {
    background-color: white !important;
    width: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    padding: 30px;
  }
  .header {
    /* transform: scale(2.5) !important; */
  }
}
@media (max-width: 500px) {
  .custom-btn.text-white {
    padding: 5px 7px;
    font-size: 12px;
  }
  .letter {
    margin: 0;
  }
  .website-content {
    padding-top: 30px !important;
  }
  .header .letter svg {
    /* width: 100%; */
    /* height: 70px !important; */
  }
  .y-10 {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--twM-scale-x)) scaleY(var(--twM-scale-y)) !important;
  }
  .header {
    /* transform: scale(1.8) !important; */
  }
}
@media (max-width: 700px) {
  .header {
    top: 100px !important;
    left: 0;
    height: auto !important;
  }
  .flotting-whatsapp {
    bottom: 130px !important;
    right: 15px !important;
  }
}
@media (max-width: 479px) {
  /* .header {
    top: -200px !important;
  } */
}
@media (max-width: 479px) {
  .header {
    /* top: 50px !important; */
    left: 0;
    height: auto !important;
  }
}
