#about {
  padding-top: 75px;
  .share-your-visions {
    background-color: #02a5bf;
    // background-image: url("../../../Assets/img/about/back-1.png");
    background-size: cover;
    background-position: center;
    height: 38vh;
    color: white;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Lato";
    font-size: 22px;
  }
  .share-your-visions p {
    text-align: center;
  }
  .share-your-visions-title h1 {
    font-weight: 1000;
    font-size: 60px;
  }

  .share-your-visions-title p {
    font-size: 21px;
    margin-top: 9px;
  }

  .one-stop-dry-cleaning {
    display: flex;
    justify-content: center;
    margin-top: 6vw;
  }

  .one-stop-dry-cleaning-content {
    margin-top: 19px;
  }
  .content-one-stop-dry-cleaning h1 {
    font-size: 40px;
    font-weight: 600;
    font-family: "Lato";
  }

  .content-one-stop-dry-cleaning p:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
    color: #333;
  }

  .content-one-stop-dry-cleaning p:nth-child(3) {
    // margin-top: 19px;
    line-height: 24px;
    font-size: 16px;
    font-family: "Lato";
  }
  .site-inductions p {
    display: flex;
    gap: 9px;
    font-size: 18px;
    color: #252525;
  }

  .site-inductions {
    display: flex;
    gap: 76px;
    // margin-top: 12px;
    white-space: nowrap;
  }
  .site-induction-icon p {
    display: flex;
    gap: 9px;
    font-size: 18px;
    font-family: "Lato";
    color: #252525;
  }
  .site-induction-all-icons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .btn-place-order button {
    margin-top: 22px;
    margin-left: 8px;
    height: 8vh;
    font-weight: bold;
    font-family: "Lato";
    font-size: 17px;
    border: none;
    background: #ffed66;
    border-radius: 6px;
    padding: 8px 40px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 768px) {
  #about {
    .one-stop-dry-cleaning {
      display: block;
      img {
        width: 100%;
        height: 400px !important;
        object-fit: cover !important;
        border-radius: 6px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  #about {
    padding-top: 0;
    .one-stop-dry-cleaning {
      display: block;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover !important;
        border-radius: 6px;
      }
    }
    .site-inductions {
      display: block;
    }
    .share-your-visions-title {
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
}
